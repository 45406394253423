import React from "react"
import styles from "../../assets/scss/member/Shop.module.scss"

export default () => {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <h2>箕澤屋ショップ</h2>
        <div className={styles.lead}>
          <p>
            箕澤屋オリジナル商品や関連商品の紹介コーナーです。何れはここから購入できることを計画中。
          </p>
        </div>
        <div className={styles.shop}>
          <ul className={styles.shop__items}>
            <li className={styles.shop__item}>
              <a
                href="https://www.facebook.com/GreenRoadFarm/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.shop__item__link}
              >
                <img
                  src={require(`../../assets/images/member/shop/img_fruits.jpg`)}
                  alt="フルーツ"
                />
                <div className={styles.shop__item__text}>
                  <h4>自家農園の生フルーツ</h4>
                  <p>
                    8月以降は桃、ワッサーなどが収穫予定。気になる方は、Facebookページを要チェック。シーズン時には食べチョクでも販売中。
                  </p>
                </div>
              </a>
            </li>
            <li className={styles.shop__item}>
              <a
                href="https://www.inadazebrewing.com/shop"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.shop__item__link}
              >
                <img
                  src={require(`../../assets/images/member/shop/img_hazydaze.jpg`)}
                  alt="HAZY DAZE"
                />
                <div className={styles.shop__item__text}>
                  <h4>HAZY DAZE with NECTARINE</h4>
                  <p>
                    箕澤屋自家農園のネクタリンを使ったビール。In a daze
                    brewingにて7月末〜提供開始予定！
                  </p>
                </div>
              </a>
            </li>
            <li className={styles.shop__item}>
              <a
                href="https://www.facebook.com/bacca.brewing/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.shop__item__link}
              >
                <img
                  src={require(`../../assets/images/member/shop/img_bacca.jpg`)}
                  alt="HAZY DAZE"
                />
                <div className={styles.shop__item__text}>
                  <h4>麦香ブルーイング あんず＆プラムビール</h4>
                  <p>
                    自家農園のあんずとプラムのビールを製造予定とのこと！仕込み日は未定のため、最新情報はFacebookページにて。
                  </p>
                </div>
              </a>
            </li>
            <li className={styles.shop__item}>
              <a
                href="https://misawayanohanashi.com/apricot-jam-recipe/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.shop__item__link}
              >
                <img
                  src={require(`../../assets/images/member/shop/img_apricotjam.jpg`)}
                  alt="あんずジャム"
                />
                <div className={styles.shop__item__text}>
                  <h4>箕澤屋あんずジャム</h4>
                  <p>
                    あいにく今年はあんずが不作により、ジャムの製造ができませんでした。あんずが手に入った方は自作しましょう。
                  </p>
                </div>
              </a>
            </li>
            <li className={styles.shop__item}>
              <a
                href="https://misawayanohanashi.com/apricot-kakigori/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.shop__item__link}
              >
                <img
                  src={require(`../../assets/images/member/shop/img_kakigori.jpg`)}
                  alt="かき氷"
                />
                <div className={styles.shop__item__text}>
                  <h4>フルーツソースのふわふわかき氷</h4>
                  <p>
                    あいにく2020年は夏季営業ができないため、今年は食べることができません。来年に期待！
                  </p>
                </div>
              </a>
            </li>
            <li className={styles.shop__item}>
              <a
                href="https://www.instagram.com/p/B0FDE4ohbjB/"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.shop__item__link}
              >
                <img
                  src={require(`../../assets/images/member/shop/img_shirt_1861.jpg`)}
                  alt="Tシャツ"
                />
                <div className={styles.shop__item__text}>
                  <h4>オリジナルTシャツ</h4>
                  <p>
                    オリジナルTシャツ第二弾！箕澤屋が建設された1861年がモチーフ。デザインのみで未生産。リクエストがあれば、生産の可能性あり。
                  </p>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
